import { useEffect, useRef, useCallback } from 'react';
import performance from '../utils/performance';

interface UsePerformanceOptions {
  componentName?: string;
  trackMount?: boolean;
  trackRender?: boolean;
}

export const usePerformance = ({
  componentName = 'Unknown',
  trackMount = true,
  trackRender = false,
}: UsePerformanceOptions = {}) => {
  const mountTime = useRef(0);
  const renderTime = useRef(0);

  // Track component mount time
  useEffect(() => {
    if (trackMount) {
      mountTime.current = window.performance.now();
      
      return () => {
        const mountDuration = window.performance.now() - mountTime.current;
        performance.trackRender(`${componentName}_mount`, mountTime.current);
      };
    }
  }, [componentName, trackMount]);

  // Track render time
  useEffect(() => {
    if (trackRender) {
      renderTime.current = window.performance.now();
      
      return () => {
        const renderDuration = window.performance.now() - renderTime.current;
        performance.trackRender(`${componentName}_render`, renderTime.current);
      };
    }
  });

  // Track API calls
  const trackApiCall = useCallback((endpoint: string) => {
    const startTime = window.performance.now();
    return {
      end: () => performance.trackApiCall(endpoint, startTime),
    };
  }, []);

  // Track custom timing
  const trackTiming = useCallback((label: string) => {
    const startTime = window.performance.now();
    return {
      end: () => {
        const endTime = window.performance.now();
        const duration = Math.round(endTime - startTime);
        return duration;
      },
    };
  }, []);

  // Track interaction timing
  const trackInteraction = useCallback((interactionName: string) => {
    const startTime = window.performance.now();
    return () => {
      const endTime = window.performance.now();
      const duration = Math.round(endTime - startTime);
      performance.trackRender(`${componentName}_${interactionName}`, startTime);
      return duration;
    };
  }, [componentName]);

  // Get current metrics
  const getMetrics = useCallback(() => {
    return performance.getMetrics();
  }, []);

  return {
    trackApiCall,
    trackTiming,
    trackInteraction,
    getMetrics,
  };
};

export default usePerformance;
