interface AnalyticsEvent {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

interface PageView {
  path: string;
  title: string;
  referrer?: string;
}

class Analytics {
  private initialized: boolean = false;
  private queue: Array<() => void> = [];

  // Initialize analytics
  init(measurementId: string) {
    if (typeof window === 'undefined') return;

    try {
      // Load Google Analytics script
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      window.gtag = function() {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', measurementId, {
        send_page_view: false // We'll handle page views manually
      });

      this.initialized = true;
      
      // Process queued events
      this.processQueue();
    } catch (error) {
      console.error('Failed to initialize analytics:', error);
    }
  }

  // Track page view
  pageView({ path, title, referrer }: PageView) {
    this.queueOrExecute(() => {
      if (!window.gtag) return;

      window.gtag('event', 'page_view', {
        page_path: path,
        page_title: title,
        page_referrer: referrer,
      });
    });
  }

  // Track event
  event({ category, action, label, value, nonInteraction = false }: AnalyticsEvent) {
    this.queueOrExecute(() => {
      if (!window.gtag) return;

      window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
        non_interaction: nonInteraction,
      });
    });
  }

  // Track search
  trackSearch(searchTerm: string, category?: string) {
    this.queueOrExecute(() => {
      if (!window.gtag) return;

      window.gtag('event', 'search', {
        search_term: searchTerm,
        search_category: category,
      });
    });
  }

  // Track form submission
  trackFormSubmission(formName: string, success: boolean) {
    this.queueOrExecute(() => {
      if (!window.gtag) return;

      window.gtag('event', 'form_submission', {
        form_name: formName,
        success: success,
      });
    });
  }

  // Track outbound link
  trackOutboundLink(url: string, category: string = 'outbound') {
    this.queueOrExecute(() => {
      if (!window.gtag) return;

      window.gtag('event', 'click', {
        event_category: category,
        event_label: url,
        transport_type: 'beacon',
      });
    });
  }

  // Track user timing
  timing(category: string, variable: string, value: number, label?: string) {
    this.queueOrExecute(() => {
      if (!window.gtag) return;

      window.gtag('event', 'timing_complete', {
        name: variable,
        value: value,
        event_category: category,
        event_label: label,
      });
    });
  }

  // Track exceptions
  trackError(description: string, fatal: boolean = false) {
    this.queueOrExecute(() => {
      if (!window.gtag) return;

      window.gtag('event', 'exception', {
        description: description,
        fatal: fatal,
      });
    });
  }

  // Queue events before initialization
  private queueOrExecute(fn: () => void) {
    if (this.initialized) {
      fn();
    } else {
      this.queue.push(fn);
    }
  }

  // Process queued events after initialization
  private processQueue() {
    while (this.queue.length > 0) {
      const fn = this.queue.shift();
      if (fn) fn();
    }
  }
}

// Add types for window object
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

export const analytics = new Analytics();

export default analytics;
