import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Container from './Container';

interface Breadcrumb {
  label: string;
  path?: string;
}

interface PageHeaderProps {
  title: string;
  description?: string;
  backgroundImage?: string;
  breadcrumbs?: Breadcrumb[];
}

const HeaderWrapper = styled.div<{ backgroundImage?: string }>`
  position: relative;
  padding: 12rem 0 8rem; // Increased top padding to account for header
  background: ${props => props.backgroundImage 
    ? `linear-gradient(to bottom, 
        rgba(255, 255, 255, 1) 0%, 
        rgba(255, 255, 255, 0.98) 15%,
        rgba(255, 255, 255, 0.95) 100%
      ), url(${props.backgroundImage})`
    : 'var(--background)'};
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to bottom, transparent, var(--background));
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--gradient-1);
    opacity: 0.05;
  }
`;

const HeaderContent = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 1080px;
  margin: 0 auto;
  animation: fadeInUp 1s ease forwards;

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Title = styled.h1`
  font-size: clamp(2.5rem, 5vw, 4rem);
  font-weight: 800;
  margin-bottom: 1.5rem;
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.2;
  letter-spacing: -0.02em;
  position: relative;
  display: inline-block;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.75rem;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: var(--gradient-1);
    border-radius: 2px;
    opacity: 0.8;
  }
`;

const Description = styled.p`
  font-size: clamp(1.125rem, 2vw, 1.375rem);
  color: var(--text);
  max-width: 800px;
  margin: 2.5rem auto 0;
  line-height: 1.6;
  opacity: 0;
  animation: fadeIn 1s ease forwards 0.5s;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const Breadcrumbs = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 3rem;
  color: var(--text-light);
  font-size: 0.9375rem;
  opacity: 0;
  animation: fadeIn 1s ease forwards 0.25s;

  a {
    color: var(--text-light);
    text-decoration: none;
    transition: all 0.3s ease;
    position: relative;

    &:hover {
      color: var(--primary);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background: var(--primary);
      transform: scaleX(0);
      transition: transform 0.3s ease;
      transform-origin: right;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  span {
    color: var(--text-light);
    display: flex;
    align-items: center;

    &::before {
      content: '/';
      margin: 0 0.5rem;
    }
  }
`;

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  description,
  backgroundImage,
  breadcrumbs,
}) => {
  return (
    <HeaderWrapper backgroundImage={backgroundImage}>
      <Container>
        <HeaderContent>
          {breadcrumbs && (
            <Breadcrumbs>
              {breadcrumbs.map((item, index) => (
                <React.Fragment key={item.label}>
                  {index > 0 && <span>/</span>}
                  {item.path ? (
                    <Link to={item.path}>{item.label}</Link>
                  ) : (
                    <span>{item.label}</span>
                  )}
                </React.Fragment>
              ))}
            </Breadcrumbs>
          )}
          <Title>{title}</Title>
          {description && <Description>{description}</Description>}
        </HeaderContent>
      </Container>
    </HeaderWrapper>
  );
};

export default PageHeader;
