import analytics from './analytics';

interface PerformanceMetrics {
  fcp: number;  // First Contentful Paint
  lcp: number;  // Largest Contentful Paint
  fid: number;  // First Input Delay
  cls: number;  // Cumulative Layout Shift
  ttfb: number; // Time to First Byte
  dcl: number;  // DOMContentLoaded
  load: number; // Load
}

interface LayoutShiftEntry extends PerformanceEntry {
  hadRecentInput: boolean;
  value: number;
}

class PerformanceMonitor {
  private initialized: boolean = false;

  init() {
    if (typeof window === 'undefined' || this.initialized) return;

    this.initialized = true;
    this.observeWebVitals();
    this.observeResources();
    this.observeErrors();
    this.trackInitialMetrics();
  }

  // Track Core Web Vitals and other performance metrics
  private observeWebVitals() {
    if (!window.PerformanceObserver) return;

    // First Contentful Paint
    this.observePaint('first-contentful-paint');
    
    // Largest Contentful Paint
    this.observePaint('largest-contentful-paint');
    
    // First Input Delay
    this.observeFirstInput();
    
    // Cumulative Layout Shift
    this.observeLayoutShift();
  }

  // Track paint timing
  private observePaint(type: string) {
    try {
      const observer = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        entries.forEach(entry => {
          analytics.timing('Web Vitals', type, entry.startTime);
        });
      });

      observer.observe({ entryTypes: ['paint'] });
    } catch (error) {
      console.warn('Paint timing not supported:', error);
    }
  }

  // Track First Input Delay
  private observeFirstInput() {
    try {
      const observer = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        entries.forEach(entry => {
          const fid = (entry as PerformanceEventTiming).processingStart - entry.startTime;
          analytics.timing('Web Vitals', 'first-input-delay', fid);
        });
      });

      observer.observe({ entryTypes: ['first-input'] });
    } catch (error) {
      console.warn('First Input Delay measurement not supported:', error);
    }
  }

  // Track Layout Shifts
  private observeLayoutShift() {
    try {
      let cumulativeLayoutShift = 0;

      const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach(entry => {
          const layoutShift = entry as LayoutShiftEntry;
          if (!layoutShift.hadRecentInput) {
            cumulativeLayoutShift += layoutShift.value;
          }
        });

        analytics.timing('Web Vitals', 'cumulative-layout-shift', cumulativeLayoutShift);
      });

      observer.observe({ entryTypes: ['layout-shift'] });
    } catch (error) {
      console.warn('Layout Shift measurement not supported:', error);
    }
  }

  // Track resource loading performance
  private observeResources() {
    try {
      const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach(entry => {
          if (entry.entryType === 'resource') {
            const resource = entry as PerformanceResourceTiming;
            analytics.timing('Resource', resource.name, resource.duration);
          }
        });
      });

      observer.observe({ entryTypes: ['resource'] });
    } catch (error) {
      console.warn('Resource timing not supported:', error);
    }
  }

  // Track JavaScript errors
  private observeErrors() {
    window.addEventListener('error', (event) => {
      analytics.trackError(event.message);
    });

    window.addEventListener('unhandledrejection', (event) => {
      analytics.trackError(`Unhandled Promise Rejection: ${event.reason}`);
    });
  }

  // Track initial page load metrics
  private trackInitialMetrics() {
    window.addEventListener('load', () => {
      const timing = window.performance.timing;
      const metrics: PerformanceMetrics = {
        ttfb: timing.responseStart - timing.navigationStart,
        dcl: timing.domContentLoadedEventEnd - timing.navigationStart,
        load: timing.loadEventEnd - timing.navigationStart,
        fcp: 0,
        lcp: 0,
        fid: 0,
        cls: 0,
      };

      Object.entries(metrics).forEach(([metric, value]) => {
        if (value > 0) {
          analytics.timing('Page Load', metric, value);
        }
      });
    });
  }

  // Get current performance metrics
  getMetrics(): Partial<PerformanceMetrics> {
    if (typeof window === 'undefined') return {};

    const metrics: Partial<PerformanceMetrics> = {};
    const timing = window.performance.timing;

    if (timing) {
      metrics.ttfb = timing.responseStart - timing.navigationStart;
      metrics.dcl = timing.domContentLoadedEventEnd - timing.navigationStart;
      metrics.load = timing.loadEventEnd - timing.navigationStart;
    }

    return metrics;
  }

  // Track component render time
  trackRender(componentName: string, startTime: number) {
    const endTime = window.performance.now();
    const duration = Math.round(endTime - startTime);
    analytics.timing('Component Render', componentName, duration);
  }

  // Track API call performance
  trackApiCall(endpoint: string, startTime: number) {
    const endTime = window.performance.now();
    const duration = Math.round(endTime - startTime);
    analytics.timing('API', endpoint, duration);
  }
}

export const performance = new PerformanceMonitor();

export default performance;
