import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const pulse = keyframes`
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  width: 100%;
`;

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border: 3px solid rgba(37, 99, 235, 0.1);
  border-radius: 50%;
  border-top-color: var(--primary);
  animation: ${spin} 1s linear infinite;
`;

const SkeletonBase = styled.div<{ $height?: string }>`
  background: linear-gradient(110deg, #f5f6f7 30%, #ffffff 50%, #f5f6f7 70%);
  border-radius: 4px;
  height: ${props => props.$height || '20px'};
  animation: ${pulse} 1.5s ease-in-out 0.5s infinite;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const Loader: React.FC = () => (
  <LoaderContainer>
    <Spinner />
  </LoaderContainer>
);

export const PageLoader: React.FC = () => (
  <LoadingOverlay>
    <Spinner />
  </LoadingOverlay>
);

interface SkeletonProps {
  height?: string;
  width?: string;
  style?: React.CSSProperties;
}

export const Skeleton: React.FC<SkeletonProps> = ({ height, width, style }) => (
  <SkeletonBase
    $height={height}
    style={{ width, ...style }}
  />
);

export const CardSkeleton: React.FC = () => (
  <div style={{ padding: '1rem' }}>
    <Skeleton height="200px" style={{ marginBottom: '1rem' }} />
    <Skeleton height="24px" width="60%" style={{ marginBottom: '0.5rem' }} />
    <Skeleton height="16px" width="90%" style={{ marginBottom: '1rem' }} />
    <Skeleton height="16px" width="80%" style={{ marginBottom: '0.5rem' }} />
    <Skeleton height="16px" width="70%" />
  </div>
);

export const TextSkeleton: React.FC = () => (
  <>
    <Skeleton width="90%" style={{ marginBottom: '0.5rem' }} />
    <Skeleton width="85%" style={{ marginBottom: '0.5rem' }} />
    <Skeleton width="80%" />
  </>
);

interface LoadingStateProps {
  loading: boolean;
  children: React.ReactNode;
  skeleton?: React.ReactNode;
}

export const LoadingState: React.FC<LoadingStateProps> = ({
  loading,
  children,
  skeleton = <Loader />
}) => {
  if (loading) {
    return <>{skeleton}</>;
  }

  return <>{children}</>;
};

export default {
  Loader,
  PageLoader,
  Skeleton,
  CardSkeleton,
  TextSkeleton,
  LoadingState,
};
