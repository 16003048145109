import React from 'react';
import styled from 'styled-components';
import PageHeader from '../components/common/PageHeader';
import Container from '../components/common/Container';
import Card from '../components/common/Card';
import Button from '../components/common/Button';
import useSEO from '../hooks/useSEO';
import { pageMeta } from '../utils/seo';

const AboutSection = styled.section`
  padding: 4rem 0;
  margin-top: -4rem;
  position: relative;
  background: var(--background);
  border-radius: 2rem 2rem 0 0;
  z-index: 2;
`;

const HistorySection = styled.section`
  padding: 4rem 0;
  background: white;
  text-align: left;
`;

const HistoryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .history-content {
    h2 {
      font-size: clamp(1.75rem, 4vw, 2.5rem);
      margin-bottom: 1.5rem;
      background: var(--gradient-1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    p {
      color: var(--text);
      font-size: 1.125rem;
      line-height: 1.8;
      margin-bottom: 1.5rem;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 2rem 0;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        color: var(--text);
        font-size: 1.125rem;

        i {
          color: var(--primary);
          margin-right: 1rem;
          font-size: 1.25rem;
        }
      }
    }
  }

  .history-image {
    position: relative;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
`;

const LeadershipSection = styled.section`
  padding: 6rem 0;
  background: linear-gradient(135deg, rgba(37, 99, 235, 0.05) 0%, rgba(79, 70, 229, 0.05) 100%);
  text-align: center;
  margin-top: 4rem;
`;

const LeadershipTitle = styled.h2`
  font-size: clamp(2rem, 5vw, 3rem);
  margin-bottom: 4rem;
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const LeadershipGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`;

const LeaderCard = styled.div`
  text-align: center;

  .image-container {
    width: 200px;
    height: 200px;
    margin: 0 auto 1.5rem;
    border-radius: 50%;
    overflow: hidden;
    border: 3px solid var(--primary);
    box-shadow: 0 8px 24px rgba(37, 99, 235, 0.15);
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--text);
  }

  .title {
    color: var(--primary);
    font-weight: 600;
    margin-bottom: 1rem;
  }

  p {
    color: var(--text-light);
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }

  .linkedin {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--primary);
    color: white;
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);

    &:hover {
      background: var(--primary-dark);
      transform: translateY(-2px);
      box-shadow: 0 6px 16px rgba(37, 99, 235, 0.3);
    }

    i {
      font-size: 1.25rem;
    }
  }
`;

const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 4rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ValueCard = styled(Card)`
  text-align: center;
  padding: 2rem;

  i {
    font-size: 2.5rem;
    color: var(--primary);
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: var(--text);
  }

  p {
    color: var(--text-light);
    line-height: 1.6;
  }
`;

const About: React.FC = () => {
  useSEO({
    ...pageMeta.about,
    url: '/about',
  });

  return (
    <>
      <PageHeader
        title="About Us"
        description="Over a decade of excellence in enterprise communication solutions"
        backgroundImage="https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&w=1920&q=80"
        breadcrumbs={[
          { label: 'Home', path: '/' },
          { label: 'About' }
        ]}
      />

      <AboutSection>
        <Container>
          <div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
            <h2 className="text-gradient" style={{ fontSize: 'clamp(2rem, 5vw, 3rem)', marginBottom: '2rem' }}>
              Our Mission
            </h2>
            <p style={{ fontSize: '1.25rem', color: 'var(--text)', lineHeight: 1.6, marginBottom: '3rem' }}>
              At DialRAM, we're dedicated to transforming business communications through innovative VoIP, 
              Dialer, and PBX solutions. Our mission is to empower organizations with seamless, reliable, 
              and cutting-edge communication technology that drives growth and enhances operational efficiency.
            </p>
          </div>
        </Container>
      </AboutSection>

      <HistorySection>
        <Container>
          <HistoryGrid>
            <div className="history-content">
              <h2>A Decade of Excellence</h2>
              <p>
                Since our founding, DialRAM has been at the forefront of communication technology innovation. 
                Our journey began with a simple vision: to provide businesses with reliable, efficient, and 
                cutting-edge communication solutions that drive success.
              </p>
              <ul>
                <li>
                  <i className="fas fa-check-circle"></i>
                  Over 10 years of industry experience
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  Thousands of successful implementations
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  99.9% uptime guarantee
                </li>
                <li>
                  <i className="fas fa-check-circle"></i>
                  24/7 expert technical support
                </li>
              </ul>
            </div>
            <div className="history-image">
              <img 
                src="https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&w=800&q=80" 
                alt="DialRAM office"
              />
            </div>
          </HistoryGrid>
        </Container>
      </HistorySection>

      <Container>
        <ValuesGrid>
          <ValueCard>
            <i className="fas fa-rocket"></i>
            <h3>Innovation</h3>
            <p>
              Continuously pushing boundaries to deliver cutting-edge communication solutions that keep our clients ahead.
            </p>
          </ValueCard>

          <ValueCard>
            <i className="fas fa-shield-alt"></i>
            <h3>Reliability</h3>
            <p>
              Ensuring rock-solid uptime and dependable service that businesses can count on 24/7.
            </p>
          </ValueCard>

          <ValueCard>
            <i className="fas fa-handshake"></i>
            <h3>Partnership</h3>
            <p>
              Building lasting relationships with our clients through trust, transparency, and dedicated support.
            </p>
          </ValueCard>
        </ValuesGrid>
      </Container>

      <LeadershipSection>
        <Container>
          <LeadershipTitle>Leadership</LeadershipTitle>
          
          <LeadershipGrid>
            <LeaderCard>
              <div className="image-container">
                <img src="/alex.jpg" alt="Alexander Maafa" />
              </div>
              <h3>Alexander Maafa</h3>
              <div className="title">Co-Founder</div>
              <p>
                Leading innovation in communication technology solutions
              </p>
              <a 
                href="https://www.linkedin.com/in/alex-maafa-a37b1b91/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="linkedin"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </LeaderCard>

            <LeaderCard>
              <div className="image-container">
                <img src="/yosef.jpeg" alt="Yousef Kohanbash" />
              </div>
              <h3>Yousef Kohanbash</h3>
              <div className="title">Co-Founder</div>
              <p>
                Driving excellence in business communications
              </p>
              <a 
                href="https://www.linkedin.com/in/yosef-kohan-8a9488183/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="linkedin"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </LeaderCard>
          </LeadershipGrid>
        </Container>
      </LeadershipSection>

      <Container>
        <div style={{ padding: '6rem 0', textAlign: 'center' }}>
          <h2 className="text-gradient" style={{ fontSize: 'clamp(2rem, 5vw, 3rem)', marginBottom: '2rem' }}>
            Ready to Transform Your Communication?
          </h2>
          <p style={{ fontSize: '1.25rem', color: 'var(--text)', maxWidth: '800px', margin: '0 auto 2rem' }}>
            Join the countless businesses that have revolutionized their communication infrastructure with DialRAM.
          </p>
          <Button to="/contact" size="large">Get Started</Button>
        </div>
      </Container>
    </>
  );
};

export default About;
