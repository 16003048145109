import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const Main = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  // Add padding to account for fixed header
  > *:first-child {
    padding-top: 0; // PageHeader components handle their own padding
  }

  // For pages without PageHeader
  > *:not(:has(.page-header)):first-child {
    padding-top: 6rem;
  }
`;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
