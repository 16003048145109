import React from 'react';
import styled from 'styled-components';
import PageHeader from '../components/common/PageHeader';
import Container from '../components/common/Container';
import Card from '../components/common/Card';
import Button from '../components/common/Button';

const ServicesSection = styled.section`
  padding: 4rem 0;
  margin-top: -4rem;
  position: relative;
  background: var(--background);
  border-radius: 2rem 2rem 0 0;
  z-index: 2;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 2rem 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background: var(--surface);
  border-radius: 0.75rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateX(5px);
  }

  i {
    color: var(--primary);
    font-size: 1.25rem;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(37, 99, 235, 0.1);
    border-radius: 12px;
    flex-shrink: 0;
  }

  div {
    flex: 1;
  }

  strong {
    display: block;
    margin-bottom: 0.25rem;
    color: var(--text);
    font-size: 1.125rem;
  }

  p {
    margin: 0;
    color: var(--text-light);
    font-size: 0.9375rem;
    line-height: 1.6;
  }
`;

const Section = styled.section`
  padding: 4rem 0;
  
  &.gradient-bg {
    background: linear-gradient(135deg, rgba(37, 99, 235, 0.1) 0%, rgba(79, 70, 229, 0.1) 100%);
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--gradient-1);
    }
  }
`;

const Overview = styled.div`
  max-width: 800px;
  margin: 0 auto 4rem;
  text-align: center;
  
  p {
    font-size: 1.25rem;
    line-height: 1.8;
    color: var(--text);
  }
`;

const Services: React.FC = () => {
  return (
    <>
      <PageHeader
        title="Enterprise Communication Solutions"
        description="Transform your business with our comprehensive suite of communication solutions"
        backgroundImage="https://images.unsplash.com/photo-1563986768609-322da13575f3?auto=format&fit=crop&w=1920&q=80"
        breadcrumbs={[
          { label: 'Home', path: '/' },
          { label: 'Solutions' }
        ]}
      />

      <ServicesSection>
        <Container>
          <Overview>
            <p>
              Discover our comprehensive range of enterprise communication solutions designed 
              to enhance your business operations. From advanced hosted dialer systems to 
              integrated VoIP and PBX solutions, we provide the tools you need to succeed.
            </p>
          </Overview>

          <ServicesGrid>
            <Card
              variant="gradient"
              interactive
              to="/services/hosted-solutions"
              title="Hosted Dialer Solutions"
              image="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=600&q=80"
              footer={<Button variant="text">Explore Solutions →</Button>}
            >
              <p>Enterprise-grade dialer hosting with advanced automation capabilities.</p>
              <FeatureList>
                <FeatureItem>
                  <i className="fas fa-server" />
                  <div>
                    <strong>High Performance</strong>
                    <p>Optimized infrastructure for maximum efficiency</p>
                  </div>
                </FeatureItem>
                <FeatureItem>
                  <i className="fas fa-shield-alt" />
                  <div>
                    <strong>Enterprise Security</strong>
                    <p>Advanced security with regular backups</p>
                  </div>
                </FeatureItem>
              </FeatureList>
            </Card>

            <Card
              variant="gradient"
              interactive
              to="/services/integrated-voip"
              title="Integrated VoIP"
              image="https://images.unsplash.com/photo-1544197150-b99a580bb7a8?auto=format&fit=crop&w=600&q=80"
              footer={<Button variant="text">Explore Solutions →</Button>}
            >
              <p>Seamless VoIP integration with crystal-clear voice quality.</p>
              <FeatureList>
                <FeatureItem>
                  <i className="fas fa-phone-volume" />
                  <div>
                    <strong>HD Voice</strong>
                    <p>Crystal clear audio quality for all calls</p>
                  </div>
                </FeatureItem>
                <FeatureItem>
                  <i className="fas fa-plug" />
                  <div>
                    <strong>Easy Integration</strong>
                    <p>Works with your existing systems</p>
                  </div>
                </FeatureItem>
              </FeatureList>
            </Card>

            <Card
              variant="gradient"
              interactive
              to="/services/pbx-solutions"
              title="PBX Solutions"
              image="https://images.unsplash.com/photo-1560264280-88b68371db39?auto=format&fit=crop&w=600&q=80"
              footer={<Button variant="text">Explore Solutions →</Button>}
            >
              <p>Advanced PBX systems for efficient call management.</p>
              <FeatureList>
                <FeatureItem>
                  <i className="fas fa-random" />
                  <div>
                    <strong>Smart Routing</strong>
                    <p>Intelligent call routing and distribution</p>
                  </div>
                </FeatureItem>
                <FeatureItem>
                  <i className="fas fa-chart-line" />
                  <div>
                    <strong>Analytics</strong>
                    <p>Detailed call analytics and reporting</p>
                  </div>
                </FeatureItem>
              </FeatureList>
            </Card>
          </ServicesGrid>
        </Container>
      </ServicesSection>

      <Section className="gradient-bg">
        <Container size="medium" centered>
          <h2 className="text-gradient">Ready to Get Started?</h2>
          <p style={{ maxWidth: '600px', margin: '2rem auto', fontSize: '1.25rem', color: 'var(--text)' }}>
            Contact us today to learn how our solutions can help transform your business communications.
          </p>
          <Button size="large" to="/contact">
            Schedule a Consultation
          </Button>
        </Container>
      </Section>
    </>
  );
};

export default Services;
