import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Container from '../common/Container';
import { ReactComponent as LogoSVG } from '../../logo.svg';

const FooterContainer = styled.footer`
  background: var(--surface);
  padding: 4rem 0 2rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--gradient-1);
  }
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  gap: 4rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const FooterColumn = styled.div`
  h3 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: var(--text);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 0.75rem;
  }

  a {
    color: var(--text-light);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary);
    }
  }
`;

const CompanyInfo = styled(FooterColumn)`
  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    text-decoration: none;

    svg {
      height: 32px;
      width: auto;
      margin-right: 0.5rem;
    }

    span {
      font-size: 1.5rem;
      font-weight: 800;
      background: var(--gradient-1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    color: var(--text-light);
    margin-bottom: 2rem;
    line-height: 1.6;
  }

  .contact-info {
    margin-top: 1.5rem;
    
    div {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;
      color: var(--text-light);
      
      i {
        width: 20px;
        margin-right: 0.75rem;
        color: var(--primary);
      }
      
      a {
        color: var(--text-light);
        text-decoration: none;
        transition: color 0.3s ease;
        
        &:hover {
          color: var(--primary);
        }
      }
    }
  }
`;

const Copyright = styled.div`
  text-align: center;
  color: var(--text-light);
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <Container>
        <FooterContent>
          <CompanyInfo>
            <Link to="/" className="logo">
              <LogoSVG />
              <span>DialRAM</span>
            </Link>
            <p>
              Transform your business communications with our enterprise-grade VoIP, 
              Dialer, PBX, and AutoDialer solutions. Experience seamless integration 
              and unparalleled performance.
            </p>
            <div className="contact-info">
              <div>
                <i className="fas fa-map-marker-alt"></i>
                30150 Briggs Rd, Menifee, CA 92584
              </div>
              <div>
                <i className="fas fa-phone"></i>
                <a href="tel:+19092789715">(909) 278-9715</a>
              </div>
              <div>
                <i className="fas fa-envelope"></i>
                <a href="mailto:info@dialram.com">info@dialram.com</a>
              </div>
              <div>
                <i className="fas fa-clock"></i>
                Mon-Fri: 9:00 AM - 6:00 PM PST
              </div>
            </div>
          </CompanyInfo>

          <FooterColumn>
            <h3>Solutions</h3>
            <ul>
              <li><Link to="/services/hosted-solutions">Hosted Solutions</Link></li>
              <li><Link to="/services/integrated-voip">Integrated VoIP</Link></li>
              <li><Link to="/services/pbx-solutions">PBX Solutions</Link></li>
              <li><Link to="/documentation">Documentation</Link></li>
            </ul>
          </FooterColumn>

          <FooterColumn>
            <h3>Company</h3>
            <ul>
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/support">Support</Link></li>
            </ul>
          </FooterColumn>

          <FooterColumn>
            <h3>Legal</h3>
            <ul>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/terms">Terms of Service</Link></li>
            </ul>
          </FooterColumn>
        </FooterContent>

        <Copyright>
          © {new Date().getFullYear()} DialRAM. All rights reserved.
        </Copyright>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
