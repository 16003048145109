import React from 'react';
import styled from 'styled-components';
import PageHeader from './PageHeader';
import Container from './Container';
import Button from './Button';

interface Feature {
  icon: string;
  title: string;
  description: string;
}

interface ServiceDetailProps {
  title: string;
  description: string;
  backgroundImage: string;
  overview: string;
  features: Feature[];
  benefits: Feature[];
}

const ContentSection = styled.section`
  padding: 8rem 0;
  background: var(--background);
  position: relative;
  z-index: 2;
`;

const OverviewSection = styled.div`
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  padding: 3rem;
  background: white;
  border-radius: 2rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 4px;
    background: var(--gradient-1);
    border-radius: 2px;
  }
`;

const OverviewContent = styled.div`
  p {
    font-size: clamp(1.125rem, 2vw, 1.375rem);
    color: var(--text);
    line-height: 1.8;
    margin-top: 2rem;
  }
`;

const FeaturesSection = styled.section`
  padding: 8rem 0;
  background: linear-gradient(135deg, rgba(37, 99, 235, 0.03) 0%, rgba(79, 70, 229, 0.03) 100%);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--gradient-1);
    opacity: 0.1;
  }
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  max-width: 1000px;
  margin: 4rem auto 0;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const FeatureCard = styled.div`
  text-align: center;
  padding: 3rem 2rem;
  background: white;
  border-radius: 1.5rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: var(--gradient-1);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);

    &::before {
      opacity: 1;
    }

    i {
      transform: scale(1.1) rotate(5deg);
    }
  }

  i {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    background: linear-gradient(135deg, var(--primary) 0%, var(--primary-dark) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    transition: transform 0.3s ease;
  }

  h3 {
    font-size: 1.375rem;
    margin-bottom: 1rem;
    color: var(--text);
    font-weight: 700;
  }

  p {
    color: var(--text-light);
    line-height: 1.7;
    font-size: 1rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: clamp(2rem, 4vw, 2.75rem);
  text-align: center;
  margin-bottom: 1.5rem;
  background: var(--gradient-1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  letter-spacing: -0.02em;
`;

const SectionDescription = styled.p`
  text-align: center;
  color: var(--text);
  font-size: clamp(1.125rem, 2vw, 1.25rem);
  max-width: 700px;
  margin: 0 auto 3rem;
  line-height: 1.6;
`;

const CTASection = styled.section`
  padding: 8rem 0;
  text-align: center;
  background: var(--background);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--gradient-1);
    opacity: 0.1;
  }
`;

const CTAContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 4rem;
  background: white;
  border-radius: 2rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: var(--gradient-1);
  }
`;

const ServiceDetailTemplate: React.FC<ServiceDetailProps> = ({
  title,
  description,
  backgroundImage,
  overview,
  features,
  benefits,
}) => {
  return (
    <>
      <PageHeader
        title={title}
        description={description}
        backgroundImage={backgroundImage}
        breadcrumbs={[
          { label: 'Home', path: '/' },
          { label: 'Solutions', path: '/services' },
          { label: title }
        ]}
      />

      <ContentSection>
        <Container>
          <OverviewSection>
            <SectionTitle>Enterprise-Grade Overview</SectionTitle>
            <OverviewContent>
              <p>{overview}</p>
            </OverviewContent>
          </OverviewSection>
        </Container>
      </ContentSection>

      <FeaturesSection>
        <Container>
          <SectionTitle>Key Features</SectionTitle>
          <SectionDescription>
            Discover the powerful features that make our solution stand out
          </SectionDescription>
          <FeaturesGrid>
            {features.map((feature, index) => (
              <FeatureCard key={index}>
                <i className={feature.icon}></i>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </FeatureCard>
            ))}
          </FeaturesGrid>
        </Container>
      </FeaturesSection>

      <ContentSection>
        <Container>
          <SectionTitle>Benefits</SectionTitle>
          <SectionDescription>
            Experience the advantages of choosing our solution
          </SectionDescription>
          <FeaturesGrid>
            {benefits.map((benefit, index) => (
              <FeatureCard key={index}>
                <i className={benefit.icon}></i>
                <h3>{benefit.title}</h3>
                <p>{benefit.description}</p>
              </FeatureCard>
            ))}
          </FeaturesGrid>
        </Container>
      </ContentSection>

      <CTASection>
        <Container>
          <CTAContent>
            <SectionTitle>Ready to Transform Your Communications?</SectionTitle>
            <SectionDescription>
              Take your business to the next level with our enterprise-grade solutions
            </SectionDescription>
            <Button to="/contact" size="large">
              Get Started Today
            </Button>
          </CTAContent>
        </Container>
      </CTASection>
    </>
  );
};

export default ServiceDetailTemplate;
