import React from 'react';
import styled from 'styled-components';
import Button from '../components/common/Button';
import Card from '../components/common/Card';
import Container from '../components/common/Container';
import useSEO from '../hooks/useSEO';
import { pageMeta } from '../utils/seo';

const HeroSection = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, rgba(37, 99, 235, 0.1) 0%, rgba(79, 70, 229, 0.1) 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?auto=format&fit=crop&w=1920&q=80') no-repeat center center;
    background-size: cover;
    opacity: 0.1;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(to bottom, transparent, var(--background));
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  animation: fadeIn 1s ease forwards;
  max-width: 1000px;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const StatsSection = styled.section`
  padding: 4rem 0;
  background: white;
`;

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  text-align: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const StatItem = styled.div`
  .number {
    font-size: 3rem;
    font-weight: 800;
    background: var(--gradient-1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0.5rem;
    line-height: 1;
  }

  .label {
    color: var(--text);
    font-size: 1.25rem;
    font-weight: 500;
  }
`;

const ServicesSection = styled.section`
  padding: 6rem 0;
  background: var(--surface);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--gradient-1);
  }
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    flex-grow: 1;
    margin-bottom: 1.5rem;
  }
`;

const DocSection = styled.section`
  padding: 6rem 0;
  background: linear-gradient(135deg, rgba(37, 99, 235, 0.05) 0%, rgba(79, 70, 229, 0.05) 100%);
  text-align: center;
`;

const Home: React.FC = () => {
  useSEO({
    ...pageMeta.home,
    url: '/',
    image: '/images/home-hero.jpg',
  });

  return (
    <>
      <HeroSection>
        <Container>
          <HeroContent>
            <h1 className="text-gradient" style={{ fontSize: 'clamp(3rem, 8vw, 5rem)', fontWeight: 800, marginBottom: '2rem', lineHeight: 1.1 }}>
              Transform Your Communication Infrastructure
            </h1>
            <p style={{ fontSize: 'clamp(1.25rem, 3vw, 1.5rem)', color: 'var(--text)', marginBottom: '3rem', maxWidth: '800px' }}>
              With over 10 years of excellence, DialRAM delivers cutting-edge VoIP, Dialer, and PBX solutions. 
              Experience seamless integration and unparalleled performance.
            </p>
            <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              <Button to="/contact" size="large">
                Get Started
              </Button>
              <Button to="/services" variant="secondary" size="large">
                Explore Solutions
              </Button>
            </div>
          </HeroContent>
        </Container>
      </HeroSection>

      <StatsSection>
        <Container>
          <StatsGrid>
            <StatItem>
              <div className="number">10+</div>
              <div className="label">Years Experience</div>
            </StatItem>
            <StatItem>
              <div className="number">99.9%</div>
              <div className="label">Uptime</div>
            </StatItem>
            <StatItem>
              <div className="number">24/7</div>
              <div className="label">Support</div>
            </StatItem>
          </StatsGrid>
        </Container>
      </StatsSection>

      <ServicesSection>
        <Container>
          <h2 className="text-gradient" style={{ textAlign: 'center', marginBottom: '4rem', fontSize: 'clamp(2rem, 5vw, 3rem)' }}>
            Our Solutions
          </h2>
          <ServicesGrid>
            <ServiceCard
              variant="gradient"
              interactive
              to="/services/hosted-solutions"
              title="Hosted Dialer Solutions"
              subtitle="Professional hosting & automation"
              footer={<Button variant="text">Learn More →</Button>}
            >
              Professional dialer hosting with expert support and maintenance. 
              Advanced automation capabilities for enhanced operations.
            </ServiceCard>

            <ServiceCard
              variant="gradient"
              interactive
              to="/services/integrated-voip"
              title="Integrated VoIP"
              subtitle="Seamless communication"
              footer={<Button variant="text">Learn More →</Button>}
            >
              Seamless VoIP solutions that integrate perfectly with your existing systems.
              Enhanced voice quality and reliability.
            </ServiceCard>

            <ServiceCard
              variant="gradient"
              interactive
              to="/services/pbx-solutions"
              title="PBX Solutions"
              subtitle="Advanced call management"
              footer={<Button variant="text">Learn More →</Button>}
            >
              Advanced PBX systems for efficient call management and routing.
              Customize your communication flow.
            </ServiceCard>
          </ServicesGrid>
        </Container>
      </ServicesSection>

      <DocSection>
        <Container size="medium">
          <h2 className="text-gradient" style={{ marginBottom: '2rem' }}>
            Technical Documentation
          </h2>
          <p style={{ fontSize: '1.25rem', color: 'var(--text)', marginBottom: '2rem', maxWidth: '600px', margin: '0 auto 2rem' }}>
            Access our comprehensive technical documentation and guides for detailed information about our solutions.
          </p>
          <Button to="/documentation" size="large">
            Access Documentation
          </Button>
        </Container>
      </DocSection>
    </>
  );
};

export default Home;
