import React, { useState } from 'react';
import styled from 'styled-components';
import PageHeader from '../components/common/PageHeader';
import Container from '../components/common/Container';
import Card from '../components/common/Card';

const FAQSection = styled.section`
  padding: 4rem 0;
  margin-top: -4rem;
  position: relative;
  background: var(--background);
  border-radius: 2rem 2rem 0 0;
  z-index: 2;
`;

const FAQGrid = styled.div`
  display: grid;
  gap: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
`;

const FAQItem = styled(Card)<{ $isOpen: boolean }>`
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white;

  &:hover {
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
  }

  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    color: var(--text);
    font-weight: 600;
    padding: 1.5rem;
    
    i {
      transition: transform 0.3s ease;
      transform: ${props => props.$isOpen ? 'rotate(180deg)' : 'rotate(0)'};
      color: var(--primary);
      font-size: 1.25rem;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(37, 99, 235, 0.1);
      border-radius: 50%;
      flex-shrink: 0;
    }
  }

  .answer {
    padding: ${props => props.$isOpen ? '0 1.5rem 1.5rem' : '0 1.5rem'};
    margin-top: ${props => props.$isOpen ? '0' : '-1.5rem'};
    color: var(--text-light);
    height: ${props => props.$isOpen ? 'auto' : '0'};
    overflow: hidden;
    opacity: ${props => props.$isOpen ? '1' : '0'};
    transition: all 0.3s ease;
    line-height: 1.6;
  }
`;

const SearchSection = styled.div`
  margin-bottom: 3rem;
  position: relative;
  z-index: 3;
`;

const SearchInput = styled.input`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: block;
  padding: 1rem 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;
  box-shadow: var(--shadow-sm);

  &:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }

  &::placeholder {
    color: var(--text-light);
  }
`;

interface FAQItemData {
  question: string;
  answer: string;
  category: string;
}

const faqData: FAQItemData[] = [
  {
    question: "What is ViciDial hosting?",
    answer: "ViciDial hosting is a service where we manage and maintain your ViciDial infrastructure, ensuring high availability, security, and optimal performance for your call center operations.",
    category: "Services"
  },
  {
    question: "What types of businesses do you serve?",
    answer: "We serve businesses of all sizes that require professional communication solutions, including call centers, sales teams, customer service departments, and any organization needing reliable telephony infrastructure.",
    category: "General"
  },
  {
    question: "What kind of support do you provide?",
    answer: "We provide 24/7 technical support for our hosted solutions, ensuring your communication systems remain operational at all times. Our team of experts is always available to help with any issues or questions.",
    category: "Support"
  },
  {
    question: "How reliable are your VoIP solutions?",
    answer: "Our VoIP solutions maintain a 99.9% uptime guarantee, backed by redundant infrastructure and continuous monitoring to ensure consistent, high-quality voice communications.",
    category: "Technical"
  },
  {
    question: "Can I integrate your solutions with my existing systems?",
    answer: "Yes, our solutions are designed to integrate seamlessly with most existing business systems and can be customized to meet your specific requirements.",
    category: "Technical"
  },
  {
    question: "How do I get started?",
    answer: "Getting started is easy. Simply contact us through our website or give us a call, and our team will guide you through the process, beginning with understanding your needs and recommending the best solution.",
    category: "General"
  },
  {
    question: "What security measures do you have in place?",
    answer: "We implement enterprise-grade security measures including encryption, regular security audits, and secure data centers to protect your communication infrastructure and data.",
    category: "Technical"
  },
  {
    question: "Do you offer custom solutions?",
    answer: "Yes, we can customize our solutions to meet your specific business needs, whether it's scaling your existing system or developing new features to enhance your operations.",
    category: "Services"
  }
];

const FAQ: React.FC = () => {
  const [openItems, setOpenItems] = useState<number[]>([0]);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleItem = (index: number) => {
    setOpenItems(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  const filteredFAQs = faqData.filter(item =>
    item.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <PageHeader
        title="Frequently Asked Questions"
        description="Find answers to common questions about our services and solutions"
        backgroundImage="https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?auto=format&fit=crop&w=1920&q=80"
        breadcrumbs={[
          { label: 'Home', path: '/' },
          { label: 'FAQ' }
        ]}
      />

      <Container>
        <FAQSection>
          <SearchSection>
            <SearchInput
              type="text"
              placeholder="Search questions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchSection>

          <FAQGrid>
            {filteredFAQs.map((item, index) => (
              <FAQItem
                key={index}
                variant="elevated"
                $isOpen={openItems.includes(index)}
                onClick={() => toggleItem(index)}
              >
                <div className="question">
                  {item.question}
                  <i className="fas fa-chevron-down"></i>
                </div>
                <div className="answer">
                  {item.answer}
                </div>
              </FAQItem>
            ))}
          </FAQGrid>
        </FAQSection>
      </Container>
    </>
  );
};

export default FAQ;
