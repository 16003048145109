import React from 'react';
import styled from 'styled-components';
import PageHeader from '../components/common/PageHeader';
import Container from '../components/common/Container';
import Card from '../components/common/Card';

const Section = styled.section`
  padding: 4rem 0;
  margin-top: -4rem;
  position: relative;
  background: var(--background);
  border-radius: 2rem 2rem 0 0;
  z-index: 2;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;

  h2 {
    font-size: 2rem;
    margin: 3rem 0 1.5rem;
    color: var(--text);
  }

  h3 {
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
    color: var(--text);
  }

  p {
    margin-bottom: 1.5rem;
    line-height: 1.8;
    color: var(--text);
  }

  ul {
    margin-bottom: 1.5rem;
    padding-left: 2rem;

    li {
      margin-bottom: 0.75rem;
      line-height: 1.6;
      color: var(--text);
    }
  }
`;

const LastUpdated = styled.div`
  margin-bottom: 2rem;
  color: var(--text-light);
  font-size: 0.875rem;
`;

const Terms: React.FC = () => {
  return (
    <>
      <PageHeader
        title="Terms of Service"
        description="Terms and conditions for using our services"
        backgroundImage="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&w=1920&q=80"
        breadcrumbs={[
          { label: 'Home', path: '/' },
          { label: 'Terms of Service' }
        ]}
      />

      <Section>
        <Container>
          <Content>
            <LastUpdated>Last Updated: January 16, 2024</LastUpdated>

            <Card variant="elevated" style={{ padding: '2rem' }}>
              <p>
                Please read these Terms of Service carefully before using DialRam's services. 
                By using our services, you agree to be bound by these terms.
              </p>

              <h2>1. Service Agreement</h2>
              <p>
                DialRam provides VoIP, dialer, telephony, PBX, and autodialer services subject 
                to these terms. By accessing or using our services, you acknowledge that you 
                have read, understood, and agree to be bound by these terms.
              </p>

              <h2>2. Service Description</h2>
              <p>
                Our services include, but are not limited to:
              </p>
              <ul>
                <li>Hosted dialer solutions</li>
                <li>VoIP services</li>
                <li>PBX systems</li>
                <li>Technical support</li>
                <li>System maintenance</li>
              </ul>

              <h2>3. User Obligations</h2>
              <p>You agree to:</p>
              <ul>
                <li>Provide accurate account information</li>
                <li>Maintain the security of your account</li>
                <li>Use services in compliance with applicable laws</li>
                <li>Pay all charges associated with your service</li>
                <li>Not misuse or abuse our services</li>
              </ul>

              <h2>4. Service Level Agreement</h2>
              <p>
                We strive to maintain 99.9% uptime for our services. Specific service levels 
                and remedies are detailed in your service agreement.
              </p>

              <h2>5. Payment Terms</h2>
              <ul>
                <li>Services are billed according to your selected plan</li>
                <li>Payment is due upon receipt of invoice</li>
                <li>Late payments may result in service suspension</li>
                <li>All fees are non-refundable unless otherwise specified</li>
              </ul>

              <h2>6. Service Modifications</h2>
              <p>
                We reserve the right to modify, suspend, or discontinue any part of our services 
                with reasonable notice. Changes to these terms will be communicated via email 
                or through our website.
              </p>

              <h2>7. Limitation of Liability</h2>
              <p>
                Our liability is limited to the amount paid for services in the previous 12 months. 
                We are not liable for indirect, incidental, or consequential damages.
              </p>

              <h2>8. Intellectual Property</h2>
              <p>
                All content, trademarks, and intellectual property related to our services remain 
                our property. You may not use them without explicit permission.
              </p>

              <h2>9. Termination</h2>
              <p>
                Either party may terminate services with proper notice as specified in the service 
                agreement. We may terminate services immediately for violations of these terms.
              </p>

              <h2>10. Governing Law</h2>
              <p>
                These terms are governed by the laws of California. Any disputes will be resolved 
                in the courts of Orange County, California.
              </p>

              <h2>11. Contact Information</h2>
              <p>
                For questions about these terms, please contact us at:
              </p>
              <ul>
                <li>Email: legal@dialram.com</li>
                <li>Phone: (XXX) XXX-XXXX</li>
                <li>Address: Orange County, California</li>
              </ul>
            </Card>
          </Content>
        </Container>
      </Section>
    </>
  );
};

export default Terms;
