import React from 'react';
import ServiceDetailTemplate from '../../components/common/ServiceDetailTemplate';

const PbxSolutions: React.FC = () => {
  return (
    <ServiceDetailTemplate
      title="PBX Solutions"
      description="Advanced PBX systems for efficient enterprise communication management"
      backgroundImage="https://images.unsplash.com/photo-1560264280-88b68371db39?auto=format&fit=crop&w=1920&q=80"
      overview={`
        Elevate your business communications with our advanced PBX solutions. 
        Our systems combine cutting-edge technology with intuitive interfaces to deliver 
        powerful call management capabilities, seamless integration options, and 
        comprehensive analytics. Experience enterprise-grade reliability with features 
        designed to optimize your communication infrastructure.
      `}
      features={[
        {
          icon: "fas fa-random",
          title: "Intelligent Call Routing",
          description: "Advanced call distribution and routing capabilities ensure calls reach the right person or department efficiently and automatically."
        },
        {
          icon: "fas fa-project-diagram",
          title: "Unified Communications",
          description: "Integrate voice, video, messaging, and collaboration tools in one comprehensive platform for seamless communication."
        },
        {
          icon: "fas fa-sliders-h",
          title: "Advanced Controls",
          description: "Comprehensive system management tools with intuitive interfaces for easy configuration and administration."
        },
        {
          icon: "fas fa-chart-bar",
          title: "Real-Time Analytics",
          description: "In-depth reporting and monitoring capabilities provide valuable insights into your communication patterns."
        },
        {
          icon: "fas fa-mobile-alt",
          title: "Mobile Integration",
          description: "Access your PBX features from any device with our mobile-friendly solutions and remote capabilities."
        },
        {
          icon: "fas fa-shield-alt",
          title: "Enterprise Security",
          description: "Robust security measures protect your communications with encryption and advanced protocols."
        }
      ]}
      benefits={[
        {
          icon: "fas fa-route",
          title: "Optimized Call Flow",
          description: "Streamline communication paths for better customer experience and increased team productivity."
        },
        {
          icon: "fas fa-users",
          title: "Enhanced Collaboration",
          description: "Improve team coordination with integrated communication tools and features."
        },
        {
          icon: "fas fa-puzzle-piece",
          title: "Seamless Integration",
          description: "Connect with your existing business systems and tools through flexible integration options."
        },
        {
          icon: "fas fa-clock",
          title: "Time Management",
          description: "Efficient scheduling and time-based routing features help manage peak call periods."
        },
        {
          icon: "fas fa-tablet-alt",
          title: "Device Flexibility",
          description: "Access your PBX system from any device, ensuring communication continuity wherever you are."
        },
        {
          icon: "fas fa-chart-pie",
          title: "Performance Insights",
          description: "Comprehensive analytics provide valuable insights for optimizing communication efficiency."
        }
      ]}
    />
  );
};

export default PbxSolutions;
