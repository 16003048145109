import React from 'react';
import styled from 'styled-components';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'small' | 'medium' | 'large';
  centered?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
  className?: string;
}

const StyledContainer = styled.div<ContainerProps>`
  width: 100%;
  margin: ${props => props.noMargin ? '0' : '0 auto'};
  padding: ${props => props.noPadding ? '0' : '0 2rem'};
  max-width: ${props => {
    switch (props.size) {
      case 'small':
        return '768px';
      case 'medium':
        return '1024px';
      default:
        return '1080px'; // Reduced from 1280px to match header
    }
  }};
  text-align: ${props => props.centered ? 'center' : 'inherit'};
  position: relative;
  z-index: 1;
`;

const Container: React.FC<ContainerProps> = ({
  children,
  size = 'large',
  centered = false,
  noPadding = false,
  noMargin = false,
  className,
  ...props
}) => {
  return (
    <StyledContainer
      size={size}
      centered={centered}
      noPadding={noPadding}
      noMargin={noMargin}
      className={className}
      {...props}
    >
      {children}
    </StyledContainer>
  );
};

export default Container;
