import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/layout/Layout';
import ScrollToTop from './components/common/ScrollToTop';
import { GlobalStyles } from './styles/GlobalStyles';
import Button from './components/common/Button';
import Container from './components/common/Container';
import useAnalytics from './hooks/useAnalytics';
import usePerformance from './hooks/usePerformance';
import performance from './utils/performance';

// Pages
import Home from './pages/Home';
import Services from './pages/Services';
import Contact from './pages/Contact';
import About from './pages/About';
import FAQ from './pages/FAQ';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Documentation from './pages/Documentation';

// Service Detail Pages
import HostedSolutions from './pages/services/HostedSolutions';
import IntegratedVoip from './pages/services/IntegratedVoip';
import PbxSolutions from './pages/services/PbxSolutions';

const NotFound = () => {
  const { trackEvent } = useAnalytics();
  const { trackInteraction } = usePerformance({ componentName: '404Page' });

  React.useEffect(() => {
    const cleanup = trackInteraction('view');
    trackEvent('Error', '404', window.location.pathname);
    
    return () => {
      cleanup();
    };
  }, [trackEvent, trackInteraction]);

  return (
    <Container centered style={{ minHeight: '60vh', display: 'flex', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <h1 className="text-gradient" style={{ fontSize: 'clamp(3rem, 10vw, 6rem)', marginBottom: '1rem' }}>
          404
        </h1>
        <p style={{ fontSize: '1.25rem', color: 'var(--text-light)', marginBottom: '2rem' }}>
          Oops! The page you're looking for doesn't exist.
        </p>
        <Button variant="primary" onClick={() => window.history.back()}>
          Go Back
        </Button>
      </div>
    </Container>
  );
};

const AppRoutes = () => {
  // Initialize analytics with tracking ID
  useAnalytics({
    trackPageViews: true,
    measurementId: process.env.REACT_APP_GA_MEASUREMENT_ID,
  });

  // Track route performance
  const { trackInteraction } = usePerformance({ componentName: 'AppRoutes' });

  React.useEffect(() => {
    const cleanup = trackInteraction('route_change');
    
    return () => {
      cleanup();
    };
  }, [trackInteraction]);

  return (
    <Routes>
      {/* Main Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/documentation" element={<Documentation />} />
      
      {/* Support redirects to Documentation */}
      <Route path="/support" element={<Navigate to="/documentation" replace />} />
      
      {/* Service Detail Routes */}
      <Route path="/services/hosted-solutions" element={<HostedSolutions />} />
      <Route path="/services/integrated-voip" element={<IntegratedVoip />} />
      <Route path="/services/pbx-solutions" element={<PbxSolutions />} />

      {/* Legal Routes */}
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<Terms />} />

      {/* 404 Route */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const App: React.FC = () => {
  // Initialize performance monitoring
  React.useEffect(() => {
    performance.init();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <GlobalStyles />
      <Layout>
        <AppRoutes />
      </Layout>
    </Router>
  );
};

export default App;
