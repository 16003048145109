import React from 'react';
import styled from 'styled-components';
import PageHeader from '../components/common/PageHeader';
import Container from '../components/common/Container';
import Card from '../components/common/Card';

const Section = styled.section`
  padding: 4rem 0;
  margin-top: -4rem;
  position: relative;
  background: var(--background);
  border-radius: 2rem 2rem 0 0;
  z-index: 2;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;

  h2 {
    font-size: 2rem;
    margin: 3rem 0 1.5rem;
    color: var(--text);
  }

  h3 {
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
    color: var(--text);
  }

  p {
    margin-bottom: 1.5rem;
    line-height: 1.8;
    color: var(--text);
  }

  ul {
    margin-bottom: 1.5rem;
    padding-left: 2rem;

    li {
      margin-bottom: 0.75rem;
      line-height: 1.6;
      color: var(--text);
    }
  }
`;

const LastUpdated = styled.div`
  margin-bottom: 2rem;
  color: var(--text-light);
  font-size: 0.875rem;
`;

const Privacy: React.FC = () => {
  return (
    <>
      <PageHeader
        title="Privacy Policy"
        description="How we handle and protect your information"
        backgroundImage="https://images.unsplash.com/photo-1507925921958-8a62f3d1a50d?auto=format&fit=crop&w=1920&q=80"
        breadcrumbs={[
          { label: 'Home', path: '/' },
          { label: 'Privacy Policy' }
        ]}
      />

      <Section>
        <Container>
          <Content>
            <LastUpdated>Last Updated: January 16, 2024</LastUpdated>

            <Card variant="elevated" style={{ padding: '2rem' }}>
              <p>
                At DialRam, we take your privacy seriously. This Privacy Policy explains how we collect, 
                use, disclose, and safeguard your information when you use our services.
              </p>

              <h2>Information We Collect</h2>
              <h3>Personal Information</h3>
              <ul>
                <li>Name and contact information</li>
                <li>Business information</li>
                <li>Payment details</li>
                <li>Communication preferences</li>
              </ul>

              <h3>Usage Information</h3>
              <ul>
                <li>Service usage patterns</li>
                <li>Technical data and logs</li>
                <li>Device information</li>
                <li>IP addresses</li>
              </ul>

              <h2>How We Use Your Information</h2>
              <ul>
                <li>To provide and maintain our services</li>
                <li>To process your transactions</li>
                <li>To improve our services</li>
                <li>To communicate with you</li>
                <li>To comply with legal obligations</li>
              </ul>

              <h2>Information Security</h2>
              <p>
                We implement appropriate technical and organizational security measures to protect 
                your information. These measures include encryption, access controls, and regular 
                security assessments.
              </p>

              <h2>Data Retention</h2>
              <p>
                We retain your information for as long as necessary to provide our services and 
                comply with legal obligations. When no longer needed, we securely delete or 
                anonymize your information.
              </p>

              <h2>Your Rights</h2>
              <p>You have the right to:</p>
              <ul>
                <li>Access your personal information</li>
                <li>Correct inaccurate information</li>
                <li>Request deletion of your information</li>
                <li>Object to processing of your information</li>
                <li>Request data portability</li>
              </ul>

              <h2>Third-Party Services</h2>
              <p>
                Our services may integrate with third-party services. These services have their 
                own privacy policies, and we recommend reviewing them.
              </p>

              <h2>Updates to Privacy Policy</h2>
              <p>
                We may update this Privacy Policy periodically. We will notify you of any material 
                changes by posting the new Privacy Policy on this page.
              </p>

              <h2>Contact Us</h2>
              <p>
                If you have questions about this Privacy Policy or our privacy practices, please 
                contact us at:
              </p>
              <ul>
                <li>Email: privacy@dialram.com</li>
                <li>Phone: (XXX) XXX-XXXX</li>
                <li>Address: Orange County, California</li>
              </ul>
            </Card>
          </Content>
        </Container>
      </Section>
    </>
  );
};

export default Privacy;
