import React from 'react';

interface MetaProps {
  title: string;
  description: string;
  keywords?: string;
  image?: string;
  url?: string;
  type?: string;
}

const defaultMeta = {
  siteName: 'DialRAM',
  baseUrl: 'https://dialram.com',
  defaultImage: '/dialram-logo.svg',
  defaultDescription: 'Enterprise communication solutions including VoIP, Dialer, PBX, and AutoDialer services.',
  defaultKeywords: 'VoIP, Dialer, PBX, AutoDialer, Communication Solutions, Enterprise Communication',
  twitterHandle: '@dialram',
  address: '30150 Briggs Rd, Menifee, CA 92584',
  phone: '(909) 278-9715',
  email: 'info@dialram.com',
  businessHours: 'Monday - Friday: 9:00 AM - 6:00 PM PST'
};

export const updateMetaTags = ({
  title,
  description,
  keywords,
  image,
  url,
  type = 'website',
}: MetaProps) => {
  const fullTitle = `${title} | ${defaultMeta.siteName}`;
  const finalDescription = description || defaultMeta.defaultDescription;
  const finalKeywords = keywords || defaultMeta.defaultKeywords;
  const finalImage = image ? `${defaultMeta.baseUrl}${image}` : `${defaultMeta.baseUrl}${defaultMeta.defaultImage}`;
  const finalUrl = url ? `${defaultMeta.baseUrl}${url}` : defaultMeta.baseUrl;

  // Update basic meta tags
  document.title = fullTitle;
  updateMetaTag('description', finalDescription);
  updateMetaTag('keywords', finalKeywords);

  // Update Open Graph meta tags
  updateMetaTag('og:title', fullTitle);
  updateMetaTag('og:description', finalDescription);
  updateMetaTag('og:image', finalImage);
  updateMetaTag('og:url', finalUrl);
  updateMetaTag('og:type', type);
  updateMetaTag('og:site_name', defaultMeta.siteName);

  // Update Twitter meta tags
  updateMetaTag('twitter:card', 'summary_large_image');
  updateMetaTag('twitter:site', defaultMeta.twitterHandle);
  updateMetaTag('twitter:title', fullTitle);
  updateMetaTag('twitter:description', finalDescription);
  updateMetaTag('twitter:image', finalImage);

  // Update business information meta tags
  updateMetaTag('business:contact_data:street_address', defaultMeta.address);
  updateMetaTag('business:contact_data:locality', 'Menifee');
  updateMetaTag('business:contact_data:region', 'CA');
  updateMetaTag('business:contact_data:postal_code', '92584');
  updateMetaTag('business:contact_data:country_name', 'United States');
  updateMetaTag('business:contact_data:email', defaultMeta.email);
  updateMetaTag('business:contact_data:phone_number', defaultMeta.phone);
  updateMetaTag('business:hours', defaultMeta.businessHours);

  // Update canonical URL
  updateCanonicalUrl(finalUrl);
};

const updateMetaTag = (name: string, content: string) => {
  let meta = document.querySelector(`meta[name="${name}"]`) ||
             document.querySelector(`meta[property="${name}"]`);
             
  if (!meta) {
    meta = document.createElement('meta');
    if (name.startsWith('og:') || name.startsWith('business:')) {
      meta.setAttribute('property', name);
    } else {
      meta.setAttribute('name', name);
    }
    document.head.appendChild(meta);
  }
  
  meta.setAttribute('content', content);
};

const updateCanonicalUrl = (url: string) => {
  let canonical = document.querySelector('link[rel="canonical"]');
  
  if (!canonical) {
    canonical = document.createElement('link');
    canonical.setAttribute('rel', 'canonical');
    document.head.appendChild(canonical);
  }
  
  canonical.setAttribute('href', url);
};

export const pageMeta = {
  home: {
    title: 'Enterprise Communication Solutions',
    description: 'Transform your business with cutting-edge VoIP, Dialer, PBX, and AutoDialer solutions.',
    keywords: 'VoIP Solutions, Enterprise Communication, Business Phone Systems, PBX Solutions',
  },
  services: {
    title: 'Our Solutions',
    description: 'Comprehensive communication solutions including hosted dialer, VoIP, and PBX services.',
    keywords: 'Business Solutions, Communication Services, Enterprise Solutions',
  },
  about: {
    title: 'About Us',
    description: 'Over a decade of excellence in providing enterprise communication solutions.',
    keywords: 'Company History, Communication Experts, Enterprise Solutions Provider',
  },
  contact: {
    title: 'Contact Us',
    description: 'Get in touch with our team at (909) 278-9715 or visit us at 30150 Briggs Rd, Menifee, CA 92584.',
    keywords: 'Contact Information, Support, Business Inquiries, Phone Number, Address',
  },
  support: {
    title: '24/7 Support',
    description: 'Expert technical support and resources for our communication solutions.',
    keywords: 'Technical Support, Customer Service, Help Resources',
  },
  privacy: {
    title: 'Privacy Policy',
    description: 'Learn how we protect and handle your information.',
    keywords: 'Privacy Policy, Data Protection, Information Security',
  },
  terms: {
    title: 'Terms of Service',
    description: 'Terms and conditions for using our communication solutions.',
    keywords: 'Terms of Service, Legal Terms, Service Agreement',
  },
  faq: {
    title: 'FAQ',
    description: 'Frequently asked questions about our communication solutions.',
    keywords: 'FAQ, Help, Common Questions',
  },
};

export default {
  updateMetaTags,
  pageMeta,
  defaultMeta,
};
