import React from 'react';
import ServiceDetailTemplate from '../../components/common/ServiceDetailTemplate';

const HostedSolutions: React.FC = () => {
  return (
    <ServiceDetailTemplate
      title="Hosted Dialer Solutions"
      description="Enterprise-grade hosted platform with advanced automation capabilities"
      backgroundImage="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=1920&q=80"
      overview={`
        Transform your contact center operations with our comprehensive hosted dialer solutions. 
        Built on enterprise-grade infrastructure, our platform delivers exceptional reliability, 
        scalability, and advanced automation capabilities. Experience seamless integration, 
        powerful features, and expert support that drive operational efficiency and growth.
      `}
      features={[
        {
          icon: "fas fa-server",
          title: "Enterprise Infrastructure",
          description: "High-performance hosting with redundant systems, load balancing, and 99.9% uptime guarantee for uninterrupted operations."
        },
        {
          icon: "fas fa-robot",
          title: "Smart Automation",
          description: "Advanced dialing algorithms and intelligent automation tools optimize agent productivity and campaign performance."
        },
        {
          icon: "fas fa-chart-line",
          title: "Real-Time Analytics",
          description: "Comprehensive reporting and analytics dashboard provides actionable insights for data-driven decision making."
        },
        {
          icon: "fas fa-shield-alt",
          title: "Enterprise Security",
          description: "Bank-grade security measures with encryption, regular backups, and continuous monitoring protect your operations."
        },
        {
          icon: "fas fa-users-cog",
          title: "Advanced Management",
          description: "Intuitive management interface with granular controls for campaigns, users, and system settings."
        },
        {
          icon: "fas fa-plug",
          title: "Integration Ready",
          description: "Pre-built integrations and APIs enable seamless connection with your CRM and business tools."
        }
      ]}
      benefits={[
        {
          icon: "fas fa-tachometer-alt",
          title: "Enhanced Productivity",
          description: "Boost agent efficiency with intelligent call distribution, automated dialing, and streamlined workflows."
        },
        {
          icon: "fas fa-expand-arrows-alt",
          title: "Unlimited Scalability",
          description: "Scale your operations instantly based on demand without hardware limitations or setup delays."
        },
        {
          icon: "fas fa-tools",
          title: "Easy Management",
          description: "Simplified administration through our user-friendly interface reduces operational complexity."
        },
        {
          icon: "fas fa-headset",
          title: "24/7 Expert Support",
          description: "Round-the-clock technical support ensures your systems run smoothly and issues are resolved quickly."
        },
        {
          icon: "fas fa-chart-pie",
          title: "Advanced Analytics",
          description: "Gain deep insights into your operations with comprehensive reporting and performance metrics."
        },
        {
          icon: "fas fa-network-wired",
          title: "Seamless Integration",
          description: "Connect with your existing systems and workflows through our flexible integration capabilities."
        }
      ]}
    />
  );
};

export default HostedSolutions;
