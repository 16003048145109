import React from 'react';
import styled from 'styled-components';
import PageHeader from '../components/common/PageHeader';
import Container from '../components/common/Container';
import Card from '../components/common/Card';
import Button from '../components/common/Button';
import useSEO from '../hooks/useSEO';
import useAnalytics from '../hooks/useAnalytics';

const DocSection = styled.section`
  padding: 4rem 0;
  margin-top: -4rem;
  position: relative;
  background: var(--background);
  border-radius: 2rem 2rem 0 0;
  z-index: 2;
`;

const AuthCard = styled(Card)`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;

  &:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
`;

const ErrorMessage = styled.div`
  color: var(--error);
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const Documentation: React.FC = () => {
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const { trackEvent } = useAnalytics();

  useSEO({
    title: 'Documentation',
    description: 'Access DialRam technical documentation and guides',
    url: '/documentation',
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Simple password check - in production, use environment variables and proper authentication
    if (password === 'dialram2024') {
      trackEvent('Documentation', 'Access Granted');
      window.location.href = 'https://docs.dialram.com';
    } else {
      setError('Invalid password');
      trackEvent('Documentation', 'Access Denied');
    }
  };

  return (
    <>
      <PageHeader
        title="Documentation"
        description="Access technical documentation and guides"
        backgroundImage="https://images.unsplash.com/photo-1432821596592-e2c18b78144f?auto=format&fit=crop&w=1920&q=80"
        breadcrumbs={[
          { label: 'Home', path: '/' },
          { label: 'Documentation' }
        ]}
      />

      <DocSection>
        <Container>
          <AuthCard variant="elevated">
            <h2 style={{ marginBottom: '2rem' }}>Access Documentation</h2>
            <p style={{ marginBottom: '2rem', color: 'var(--text-light)' }}>
              Please enter your access password to view the documentation.
              Contact support if you need access.
            </p>

            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </FormGroup>

              <Button type="submit" fullWidth>
                Access Documentation
              </Button>
            </form>
          </AuthCard>
        </Container>
      </DocSection>
    </>
  );
};

export default Documentation;
