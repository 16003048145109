import React, { useState } from 'react';
import styled from 'styled-components';
import PageHeader from '../components/common/PageHeader';
import Container from '../components/common/Container';
import Card from '../components/common/Card';
import Button from '../components/common/Button';
import { LoadingState } from '../utils/loading';
import useAnalytics from '../hooks/useAnalytics';
import useSEO from '../hooks/useSEO';
import { pageMeta } from '../utils/seo';
import useLoading from '../hooks/useLoading';

const ContactSection = styled.section`
  padding: 4rem 0;
  margin-top: -4rem;
  position: relative;
  background: var(--background);
  border-radius: 2rem 2rem 0 0;
  z-index: 2;
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  margin-top: -4rem;
  position: relative;
  z-index: 10;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text);
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;

  &:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  font-size: 1rem;
  min-height: 150px;
  transition: all 0.3s ease;
  background: white;

  &:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
  }
`;

const ErrorMessage = styled.div`
  color: var(--error);
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  }

  i {
    width: 40px;
    height: 40px;
    background: var(--gradient-1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.25rem;
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
  }

  div {
    flex: 1;
  }

  h4 {
    margin: 0 0 0.5rem;
    color: var(--text);
    font-size: 1.125rem;
  }

  p {
    margin: 0;
    color: var(--text-light);
    line-height: 1.6;
  }

  a {
    color: var(--text-light);
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary);
    }
  }
`;

const MapContainer = styled.div`
  margin-top: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  height: 300px;
  background: white;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;
  padding: 2rem;
  background: rgba(16, 185, 129, 0.1);
  border-radius: 1rem;
  margin-bottom: 2rem;

  i {
    font-size: 3rem;
    color: var(--success);
    margin-bottom: 1rem;
  }

  h3 {
    color: var(--success);
    margin-bottom: 1rem;
  }

  p {
    color: var(--text);
  }
`;

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: ''
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { loading, withLoading } = useLoading();
  const { trackEvent } = useAnalytics();

  useSEO({
    ...pageMeta.contact,
    url: '/contact',
  });

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    if (!formData.message.trim()) {
      newErrors.message = 'Message is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;

    try {
      await withLoading(
        // Simulate API call
        new Promise(resolve => setTimeout(resolve, 1500))
      );
      setSubmitSuccess(true);
      trackEvent('Contact', 'Form Submit', 'Success');
      setFormData({
        name: '',
        email: '',
        phone: '',
        company: '',
        message: ''
      });
    } catch (error) {
      setErrors({ submit: 'Failed to send message. Please try again.' });
      trackEvent('Contact', 'Form Submit', 'Error');
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  return (
    <>
      <PageHeader
        title="Get in Touch"
        description="Let's discuss how we can help transform your business communications"
        backgroundImage="https://images.unsplash.com/photo-1516321318423-f06f85e504b3?auto=format&fit=crop&w=1920&q=80"
        breadcrumbs={[
          { label: 'Home', path: '/' },
          { label: 'Contact' }
        ]}
      />

      <Container>
        <ContactSection>
          <ContactGrid>
            <Card variant="elevated" style={{ padding: '2rem' }}>
              <LoadingState
                loading={loading}
                skeleton={
                  <>
                    <div style={{ marginBottom: '2rem' }}>
                      <div style={{ width: '60%', height: '24px', background: '#f0f0f0', marginBottom: '1rem' }} />
                      <div style={{ width: '100%', height: '40px', background: '#f0f0f0', marginBottom: '1rem' }} />
                      <div style={{ width: '100%', height: '40px', background: '#f0f0f0', marginBottom: '1rem' }} />
                      <div style={{ width: '100%', height: '100px', background: '#f0f0f0' }} />
                    </div>
                  </>
                }
              >
                {submitSuccess ? (
                  <SuccessMessage>
                    <i className="fas fa-check-circle"></i>
                    <h3>Message Sent Successfully!</h3>
                    <p>Thank you for reaching out. We'll get back to you soon.</p>
                    <Button 
                      variant="primary" 
                      onClick={() => setSubmitSuccess(false)}
                      style={{ marginTop: '1rem' }}
                    >
                      Send Another Message
                    </Button>
                  </SuccessMessage>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <FormGroup>
                      <Label htmlFor="name">Name *</Label>
                      <Input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Your name"
                      />
                      {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="email">Email *</Label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="your@email.com"
                      />
                      {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="phone">Phone Number</Label>
                      <Input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="(909) 278-9715"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="company">Company</Label>
                      <Input
                        type="text"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                        placeholder="Your company name"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label htmlFor="message">Message *</Label>
                      <TextArea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder="How can we help you?"
                      />
                      {errors.message && <ErrorMessage>{errors.message}</ErrorMessage>}
                    </FormGroup>

                    {errors.submit && <ErrorMessage style={{ marginBottom: '1rem' }}>{errors.submit}</ErrorMessage>}

                    <Button type="submit" fullWidth disabled={loading}>
                      {loading ? 'Sending...' : 'Send Message'}
                    </Button>
                  </form>
                )}
              </LoadingState>
            </Card>

            <ContactInfo>
              <InfoItem>
                <i className="fas fa-map-marker-alt"></i>
                <div>
                  <h4>Location</h4>
                  <p>30150 Briggs Rd<br />Menifee, CA 92584</p>
                </div>
              </InfoItem>

              <InfoItem>
                <i className="fas fa-phone"></i>
                <div>
                  <h4>Phone</h4>
                  <p><a href="tel:+19092789715">(909) 278-9715</a></p>
                </div>
              </InfoItem>

              <InfoItem>
                <i className="fas fa-envelope"></i>
                <div>
                  <h4>Email</h4>
                  <p><a href="mailto:info@dialram.com">info@dialram.com</a></p>
                </div>
              </InfoItem>

              <InfoItem>
                <i className="fas fa-clock"></i>
                <div>
                  <h4>Business Hours</h4>
                  <p>Monday - Friday: 9:00 AM - 6:00 PM PST</p>
                </div>
              </InfoItem>

              <MapContainer>
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.0888287242424!2d-117.1518123!3d33.7424567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dc9c3c0d4c8b81%3A0x2b8f6d800665d147!2s30150%20Briggs%20Rd%2C%20Menifee%2C%20CA%2092584!5e0!3m2!1sen!2sus!4v1642374523456!5m2!1sen!2sus"
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="DialRAM Office Location"
                ></iframe>
              </MapContainer>
            </ContactInfo>
          </ContactGrid>
        </ContactSection>
      </Container>
    </>
  );
};

export default Contact;
