import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import analytics from '../utils/analytics';

interface UseAnalyticsOptions {
  trackPageViews?: boolean;
  measurementId?: string;
}

export const useAnalytics = (options: UseAnalyticsOptions = {}) => {
  const location = useLocation();
  const { trackPageViews = true, measurementId } = options;

  // Initialize analytics
  useEffect(() => {
    if (measurementId) {
      analytics.init(measurementId);
    }
  }, [measurementId]);

  // Track page views
  useEffect(() => {
    if (trackPageViews) {
      analytics.pageView({
        path: location.pathname + location.search,
        title: document.title,
        referrer: document.referrer,
      });
    }
  }, [location, trackPageViews]);

  // Track event wrapper
  const trackEvent = useCallback((category: string, action: string, label?: string, value?: number) => {
    analytics.event({
      category,
      action,
      label,
      value,
    });
  }, []);

  // Track form submission wrapper
  const trackFormSubmission = useCallback((formName: string, success: boolean) => {
    analytics.trackFormSubmission(formName, success);
  }, []);

  // Track search wrapper
  const trackSearch = useCallback((searchTerm: string, category?: string) => {
    analytics.trackSearch(searchTerm, category);
  }, []);

  // Track outbound link wrapper
  const trackOutboundLink = useCallback((url: string, category?: string) => {
    analytics.trackOutboundLink(url, category);
  }, []);

  // Track timing wrapper
  const trackTiming = useCallback((category: string, variable: string, value: number, label?: string) => {
    analytics.timing(category, variable, value, label);
  }, []);

  // Track error wrapper
  const trackError = useCallback((description: string, fatal: boolean = false) => {
    analytics.trackError(description, fatal);
  }, []);

  // Track component mount time
  const trackMountTime = useCallback((componentName: string) => {
    const startTime = performance.now();
    return () => {
      const endTime = performance.now();
      const loadTime = Math.round(endTime - startTime);
      analytics.timing('Component', 'mount', loadTime, componentName);
    };
  }, []);

  // Track interaction time
  const trackInteractionTime = useCallback((category: string, action: string) => {
    const startTime = performance.now();
    return () => {
      const endTime = performance.now();
      const duration = Math.round(endTime - startTime);
      analytics.timing(category, action, duration);
    };
  }, []);

  return {
    trackEvent,
    trackFormSubmission,
    trackSearch,
    trackOutboundLink,
    trackTiming,
    trackError,
    trackMountTime,
    trackInteractionTime,
  };
};

export default useAnalytics;
