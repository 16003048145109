import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon } from '../../utils/icons';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'elevated' | 'gradient';
  interactive?: boolean;
  to?: string;
  title?: string;
  subtitle?: string;
  image?: string;
  icon?: string;
  iconType?: 'default' | 'feature' | 'benefit' | 'navigation' | 'social';
  footer?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  $isOpen?: boolean;
}

const CardContainer = styled.div<{ 
  $variant: string; 
  $interactive: boolean;
  $isOpen?: boolean;
}>`
  background: ${props => props.$variant === 'gradient' 
    ? 'linear-gradient(135deg, rgba(37, 99, 235, 0.1) 0%, rgba(79, 70, 229, 0.1) 100%)'
    : 'white'};
  border-radius: 1.5rem;
  padding: 2rem;
  box-shadow: ${props => props.$variant === 'elevated'
    ? 'var(--shadow-lg)'
    : props.$variant === 'gradient'
    ? '0 4px 20px rgba(37, 99, 235, 0.1)'
    : 'var(--shadow-sm)'};
  transition: all 0.3s ease;

  ${props => props.$interactive && `
    cursor: pointer;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: var(--shadow-xl);
    }

    &:active {
      transform: translateY(-2px);
    }
  `}
`;

const CardImage = styled.div<{ $image?: string }>`
  height: 200px;
  margin: -2rem -2rem 2rem;
  background: ${props => props.$image 
    ? `url(${props.$image}) no-repeat center center`
    : 'var(--gradient-1)'};
  background-size: cover;
  border-radius: 1.5rem 1.5rem 0 0;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--text);
`;

const CardSubtitle = styled.div`
  font-size: 1rem;
  color: var(--text-light);
  margin-bottom: 1rem;
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  background: var(--gradient-1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  transform: rotate(-5deg);
  transition: transform 0.3s ease;

  ${CardContainer}:hover & {
    transform: rotate(0deg);
  }
`;

const CardFooter = styled.div`
  margin-top: 2rem;
`;

const Card: React.FC<CardProps> = ({
  variant = 'default',
  interactive = false,
  to,
  title,
  subtitle,
  image,
  icon,
  iconType = 'default',
  footer,
  children,
  className,
  $isOpen,
  style,
  onClick,
  ...rest
}) => {
  const CardComponent = to ? Link : 'div';
  const props = to ? { to } : {};

  const content = (
    <CardContainer 
      as={CardComponent} 
      $variant={variant} 
      $interactive={interactive || !!to}
      $isOpen={$isOpen}
      className={className}
      style={style}
      onClick={onClick}
      {...props}
      {...rest}
    >
      {image && <CardImage $image={image} />}
      {icon && (
        <IconWrapper>
          <Icon name={icon} type={iconType} />
        </IconWrapper>
      )}
      {title && <CardTitle>{title}</CardTitle>}
      {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
      {children}
      {footer && <CardFooter>{footer}</CardFooter>}
    </CardContainer>
  );

  return content;
};

export default Card;
