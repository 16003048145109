import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as LogoSVG } from '../../logo.svg';

const HeaderContainer = styled.header<{ isScrolled: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: ${props => props.isScrolled 
    ? 'rgba(255, 255, 255, 0.95)' 
    : '#ffffff'};
  backdrop-filter: ${props => props.isScrolled ? 'blur(10px)' : 'none'};
  box-shadow: ${props => props.isScrolled 
    ? '0 2px 20px rgba(0, 0, 0, 0.08)' 
    : '0 1px 0 rgba(0, 0, 0, 0.05)'};
  padding: ${props => props.isScrolled ? '1rem 0' : '1.5rem 0'};
  z-index: 1000;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`;

const Nav = styled.nav`
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--primary);
  transition: all 0.3s ease;
  flex-shrink: 0;

  &:hover {
    transform: translateY(-1px);
  }

  svg {
    height: 32px;
    width: auto;
    margin-right: 0.5rem;
  }

  span {
    font-size: 1.75rem;
    font-weight: 800;
    letter-spacing: -0.5px;
    background: var(--gradient-1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2.5rem;
  align-items: center;
  margin-left: 3rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const NavLink = styled(Link)<{ $isActive?: boolean }>`
  text-decoration: none;
  color: var(--text);
  font-weight: 500;
  position: relative;
  padding: 0.5rem 0;
  font-size: 0.9375rem;
  transition: color 0.3s ease;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--gradient-1);
    transform: scaleX(${props => props.$isActive ? '1' : '0'});
    transform-origin: right;
    transition: transform 0.3s ease;
  }
  
  &:hover {
    color: var(--primary);
    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
`;

const CTAButton = styled(Link)`
  background: linear-gradient(135deg, var(--primary) 0%, var(--primary-dark) 100%);
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  font-weight: 600;
  transition: all 0.3s ease;
  font-size: 0.9375rem;
  border: 2px solid transparent;
  box-shadow: 0 4px 15px rgba(37, 99, 235, 0.2);
  position: relative;
  overflow: hidden;
  margin-left: 1rem;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, var(--primary-dark) 0%, var(--primary) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(37, 99, 235, 0.3);
    color: white;
    text-decoration: none;

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 4px 15px rgba(37, 99, 235, 0.2);
  }

  span {
    position: relative;
    z-index: 1;
  }
`;

const MobileMenuButton = styled.button<{ isOpen: boolean }>`
  display: none;
  
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 100;
    
    span {
      width: 2rem;
      height: 2px;
      background: var(--text);
      border-radius: 10px;
      transition: all 0.3s ease;
      position: relative;
      transform-origin: 1px;

      &:first-child {
        transform: ${({ isOpen }) => isOpen ? 'rotate(45deg)' : 'rotate(0)'};
      }

      &:nth-child(2) {
        opacity: ${({ isOpen }) => isOpen ? '0' : '1'};
        transform: ${({ isOpen }) => isOpen ? 'translateX(20px)' : 'translateX(0)'};
      }

      &:nth-child(3) {
        transform: ${({ isOpen }) => isOpen ? 'rotate(-45deg)' : 'rotate(0)'};
      }
    }
  }
`;

const MobileMenu = styled.div<{ isOpen: boolean }>`
  display: none;
  
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    padding: 2rem;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: ${({ isOpen }) => isOpen ? 'translateX(0)' : 'translateX(100%)'};
    z-index: 90;
  }
`;

const MobileNavLink = styled(NavLink)`
  font-size: 1.25rem;
  margin: 1rem 0;
  text-align: center;
`;

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100); // Increased scroll threshold
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close mobile menu when location changes
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  const isActive = (path: string) => location.pathname === path;

  return (
    <HeaderContainer isScrolled={isScrolled}>
      <Nav>
        <LogoLink to="/">
          <LogoSVG />
          <span>DialRAM</span>
        </LogoLink>
        
        <NavLinks>
          <NavLink to="/services" $isActive={isActive('/services')}>Solutions</NavLink>
          <NavLink to="/about" $isActive={isActive('/about')}>About</NavLink>
          <NavLink to="/faq" $isActive={isActive('/faq')}>FAQ</NavLink>
          <NavLink to="/documentation" $isActive={isActive('/documentation')}>Documentation</NavLink>
          <CTAButton to="/contact">
            <span>Contact Us</span>
          </CTAButton>
        </NavLinks>

        <MobileMenuButton 
          isOpen={isMobileMenuOpen}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          aria-label="Toggle menu"
        >
          <span />
          <span />
          <span />
        </MobileMenuButton>

        <MobileMenu isOpen={isMobileMenuOpen}>
          <MobileNavLink to="/services" $isActive={isActive('/services')}>Solutions</MobileNavLink>
          <MobileNavLink to="/about" $isActive={isActive('/about')}>About</MobileNavLink>
          <MobileNavLink to="/faq" $isActive={isActive('/faq')}>FAQ</MobileNavLink>
          <MobileNavLink to="/documentation" $isActive={isActive('/documentation')}>Documentation</MobileNavLink>
          <CTAButton to="/contact" style={{ marginTop: '1rem', textAlign: 'center' }}>
            <span>Contact Us</span>
          </CTAButton>
        </MobileMenu>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
