import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import usePerformance from '../../hooks/usePerformance';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'text' | 'outline';
  size?: 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  to?: string;
  loading?: boolean;
  className?: string;
}

const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${props => {
    switch (props.size) {
      case 'small':
        return '0.5rem 1rem';
      case 'large':
        return '1rem 2rem';
      default:
        return '0.75rem 1.5rem';
    }
  }};
  font-size: ${props => {
    switch (props.size) {
      case 'small':
        return '0.875rem';
      case 'large':
        return '1.125rem';
      default:
        return '1rem';
    }
  }};
  font-weight: 600;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  cursor: ${props => (props.disabled || props.loading ? 'not-allowed' : 'pointer')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  opacity: ${props => (props.disabled || props.loading ? 0.5 : 1)};

  ${props => {
    switch (props.variant) {
      case 'secondary':
        return `
          background: transparent;
          border: 2px solid var(--primary);
          color: var(--primary);

          &:hover:not(:disabled) {
            background: var(--primary-light);
            border-color: var(--primary-light);
            color: var(--primary);
          }
        `;
      case 'outline':
        return `
          background: transparent;
          border: 1px solid currentColor;
          color: var(--text);

          &:hover:not(:disabled) {
            background: rgba(0, 0, 0, 0.05);
            color: var(--text-dark);
          }
        `;
      case 'text':
        return `
          background: transparent;
          border: none;
          color: var(--primary);
          padding-left: 0;
          padding-right: 0;

          &:hover:not(:disabled) {
            color: var(--primary-dark);
            text-decoration: underline;
          }
        `;
      default:
        return `
          background: var(--primary);
          border: none;
          color: white;

          &:hover:not(:disabled) {
            background: var(--primary-dark);
            transform: translateY(-1px);
          }

          &:active:not(:disabled) {
            transform: translateY(0);
          }
        `;
    }
  }}
`;

const StyledLink = styled(Link)<ButtonProps>`
  text-decoration: none;
  ${StyledButton}
`;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({
  children,
  variant = 'primary',
  size = 'medium',
  fullWidth = false,
  to,
  loading = false,
  onClick,
  className,
  disabled,
  ...props
}, ref) => {
  const { trackEvent } = useAnalytics();
  const { trackInteraction } = usePerformance({ componentName: 'Button' });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (!disabled && !loading) {
      const endTracking = trackInteraction('button_click');
      trackEvent('Interaction', 'Button Click', typeof children === 'string' ? children : 'Button');
      endTracking();
      onClick?.(e as React.MouseEvent<HTMLButtonElement>);
    }
  };

  const buttonProps = {
    variant,
    size,
    fullWidth,
    disabled: disabled || loading,
    className,
    onClick: handleClick,
    ...props,
  };

  if (to) {
    return (
      <StyledLink to={to} {...buttonProps}>
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledButton ref={ref} {...buttonProps}>
      {loading ? 'Loading...' : children}
    </StyledButton>
  );
});

Button.displayName = 'Button';

export default Button;
