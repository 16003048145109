import { useEffect } from 'react';
import { updateMetaTags } from '../utils/seo';

interface SEOProps {
  title: string;
  description: string;
  keywords?: string;
  image?: string;
  url?: string;
  type?: string;
}

export const useSEO = ({
  title,
  description,
  keywords,
  image,
  url,
  type,
}: SEOProps) => {
  useEffect(() => {
    updateMetaTags({
      title,
      description,
      keywords,
      image,
      url,
      type,
    });

    // Clean up meta tags when component unmounts
    return () => {
      updateMetaTags({
        title: 'DialRam',
        description: 'Enterprise communication solutions including VoIP, Dialer, PBX, and AutoDialer services.',
        url: '/',
      });
    };
  }, [title, description, keywords, image, url, type]);
};

export default useSEO;
