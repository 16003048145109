import React from 'react';
import ServiceDetailTemplate from '../../components/common/ServiceDetailTemplate';

const IntegratedVoip: React.FC = () => {
  return (
    <ServiceDetailTemplate
      title="Integrated VoIP Solutions"
      description="Enterprise-grade VoIP solutions with seamless integration capabilities"
      backgroundImage="https://images.unsplash.com/photo-1544197150-b99a580bb7a8?auto=format&fit=crop&w=1920&q=80"
      overview={`
        Transform your business communications with our enterprise-grade VoIP solutions. 
        Experience crystal-clear voice quality, advanced features, and seamless 
        integration capabilities that enhance your team's productivity. Our robust 
        infrastructure ensures reliable performance, while our expert support team 
        ensures your systems run smoothly 24/7.
      `}
      features={[
        {
          icon: "fas fa-phone-volume",
          title: "HD Voice Quality",
          description: "Experience crystal-clear communications with our advanced codec support and quality monitoring systems. Enjoy professional audio quality that enhances every conversation."
        },
        {
          icon: "fas fa-network-wired",
          title: "Seamless Integration",
          description: "Connect effortlessly with your existing systems through our flexible integration capabilities. Compatible with major CRM platforms and business tools."
        },
        {
          icon: "fas fa-shield-alt",
          title: "Enterprise Security",
          description: "Protect your communications with enterprise-grade encryption and advanced security protocols. Regular security audits ensure your data stays safe."
        },
        {
          icon: "fas fa-project-diagram",
          title: "Smart Routing",
          description: "Optimize call flow with intelligent routing capabilities. Automatically direct calls based on time, availability, and custom rules."
        },
        {
          icon: "fas fa-chart-line",
          title: "Advanced Analytics",
          description: "Gain valuable insights with comprehensive call analytics and reporting tools. Monitor performance and make data-driven decisions."
        },
        {
          icon: "fas fa-mobile-alt",
          title: "Mobile Integration",
          description: "Stay connected on any device with our mobile-friendly solutions. Access your business phone system from anywhere, anytime."
        }
      ]}
      benefits={[
        {
          icon: "fas fa-comments",
          title: "Enhanced Communication",
          description: "Superior voice quality and reliability ensure clear, professional communications with clients and team members."
        },
        {
          icon: "fas fa-globe",
          title: "Global Connectivity",
          description: "Connect with teams and clients worldwide with minimal latency and optimal call quality across regions."
        },
        {
          icon: "fas fa-cogs",
          title: "Flexible Configuration",
          description: "Customize your setup with advanced configuration options to match your specific business requirements."
        },
        {
          icon: "fas fa-chart-bar",
          title: "Performance Monitoring",
          description: "Real-time monitoring and analytics help maintain optimal system performance and call quality."
        },
        {
          icon: "fas fa-sync",
          title: "Business Continuity",
          description: "Built-in redundancy and failover systems ensure your communications remain operational at all times."
        },
        {
          icon: "fas fa-database",
          title: "Scalable Infrastructure",
          description: "Easily scale your system up or down based on your business needs without hardware limitations."
        }
      ]}
    />
  );
};

export default IntegratedVoip;
