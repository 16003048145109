import React from 'react';

interface IconMap {
  [key: string]: string;
}

// Default icon classes if specific ones are not found
const fallbackIcons: IconMap = {
  default: 'fas fa-circle',
  feature: 'fas fa-star',
  benefit: 'fas fa-check-circle',
  navigation: 'fas fa-chevron-right',
  social: 'fas fa-share-alt',
};

// Main icon mapping
const iconMap: IconMap = {
  // Service icons
  server: 'fas fa-server',
  robot: 'fas fa-robot',
  chart: 'fas fa-chart-line',
  shield: 'fas fa-shield-alt',
  users: 'fas fa-users-cog',
  plug: 'fas fa-plug',
  phone: 'fas fa-phone-volume',
  network: 'fas fa-network-wired',
  mobile: 'fas fa-mobile-alt',
  tools: 'fas fa-tools',
  cogs: 'fas fa-cogs',
  database: 'fas fa-database',
  headset: 'fas fa-headset',
  coins: 'fas fa-coins',
  random: 'fas fa-random',
  project: 'fas fa-project-diagram',
  sliders: 'fas fa-sliders-h',
  chart_bar: 'fas fa-chart-bar',
  lock: 'fas fa-lock',
  sync: 'fas fa-sync',

  // Navigation icons
  home: 'fas fa-home',
  about: 'fas fa-info-circle',
  contact: 'fas fa-envelope',
  services: 'fas fa-cog',
  support: 'fas fa-headset',
  blog: 'fas fa-blog',
  faq: 'fas fa-question-circle',

  // Social icons
  linkedin: 'fab fa-linkedin-in',
  twitter: 'fab fa-twitter',
  facebook: 'fab fa-facebook-f',

  // UI icons
  arrow_up: 'fas fa-chevron-up',
  arrow_down: 'fas fa-chevron-down',
  arrow_right: 'fas fa-chevron-right',
  arrow_left: 'fas fa-chevron-left',
  close: 'fas fa-times',
  menu: 'fas fa-bars',
  search: 'fas fa-search',
};

/**
 * Get the icon class for a given key
 * @param key The icon key to look up
 * @param type The type of icon (for fallback purposes)
 * @returns The appropriate Font Awesome icon class
 */
export const getIcon = (key: string, type: 'default' | 'feature' | 'benefit' | 'navigation' | 'social' = 'default'): string => {
  // Convert key to lowercase and replace spaces/underscores with hyphens
  const normalizedKey = key.toLowerCase().replace(/[\s_]/g, '-');
  
  // Return the mapped icon if it exists, otherwise return the appropriate fallback
  return iconMap[normalizedKey] || fallbackIcons[type] || fallbackIcons.default;
};

/**
 * Check if an icon exists in the icon map
 * @param key The icon key to check
 * @returns boolean indicating if the icon exists
 */
export const hasIcon = (key: string): boolean => {
  const normalizedKey = key.toLowerCase().replace(/[\s_]/g, '-');
  return !!iconMap[normalizedKey];
};

/**
 * Get all available icons for a specific type
 * @param type The type of icons to retrieve
 * @returns Array of icon keys
 */
export const getIconsByType = (type: string): string[] => {
  const icons: string[] = [];
  Object.entries(iconMap).forEach(([key, value]) => {
    if (value.includes(type)) {
      icons.push(key);
    }
  });
  return icons;
};

interface IconProps extends React.HTMLAttributes<HTMLElement> {
  name: string;
  type?: 'default' | 'feature' | 'benefit' | 'navigation' | 'social';
}

/**
 * Icon component for consistent icon usage across the app
 */
export const Icon: React.FC<IconProps> = ({ name, type = 'default', className, ...props }) => {
  const iconClass = getIcon(name, type);
  return <i className={`${iconClass} ${className || ''}`} {...props} />;
};

const icons = {
  getIcon,
  hasIcon,
  getIconsByType,
  Icon,
};

export default icons;
